/*
 * @Description:本地存储
 * @Author: zhangxy
 * @Date: 2021-12-15
 */
class Storage {
    /*
     * @description: 设置cookie
     * @param : key value t
     * @return: cookie
     */
    setCookie( key, value, expires = 10000 ) {
        const oDate = new Date(); // 创建日期对象
        oDate.setDate( oDate.getDate() + expires ); // 设置过期时间
        if ( process.env.NODE_ENV === "development" ) {
            document.cookie = key + "=" + value + ";path=/;expires=" + oDate.toGMTString(); // 设置cookie的名称，数值，过期时间
            return;
        }
        document.cookie = key + "=" + value + ";expires=" + oDate.toGMTString(); // 设置cookie的名称，数值，过期时间
        // alert(`process.env.NODE_ENV: ${process.env.NODE_ENV} getCookis res is ${this.getCookie(key)}`)
    }

    /*
     * @description: 获取cookie
     * @param :name
     * @return: value
     */
    getCookie( name ) {
        const arr = document.cookie.split( "; " );
        for ( let i = 0; i < arr.length; i++ ) {
            const arr2 = arr[i].split( "=" );
            if ( arr2[0] === name ) {
                return arr2[1];
            }
        }
        return "";
    }

    /*
     * @description: 移除cookie
     * @param :
     * @return:
     */
    removeCookie( name ) {
        this.setCookie( name, 1, -1 );
    }

    /*
    * @description: 删除所有的cookie
    * @param :
    * @return:
    */
    clearCookie() {
        const arr = document.cookie.split( ";" );
        const arr1 = arr.map( v => v.split( "=" )[0] );
        arr1.forEach( v => {
            this.removeCookie( v );
        } );
    }
}

export default new Storage();
