<template>
	<div
		id="app"
	>
		<div
			class="page-container">
			<router-view/>
		</div>
	</div>
</template>
<script>
export default {
 
    data() {
        return {
        };
    },
    computed: {
    },
    watch: {
      
    },
    mounted() {
    },
    methods: {

    },
};
</script>
<style lang="less" scoped>

</style>

