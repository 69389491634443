// const proApiBaseUrl = "https://material-test.huangfengdata.com";

// const devApiBaseUrl = "https://material-test.huangfengdata.com";

const proApiBaseUrl = "https://www.dingdigit.com";

const devApiBaseUrl = "https://www.dingdigit.com";


module.exports = {
    // apiBaseUrl: process.env.NODE_ENV === "development" ? devApiBaseUrl : proApiBaseUrl
    apiBaseUrl: 'https://mobile.dingdigit.com'
};
