export default {

    // 手机号
    phone( value ) {
        if ( !/^1(3|4|5|7|8|6|9)\d{9}$/.test( value ) ) {
            return false;
        }
        return true;
    }

};
