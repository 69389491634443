import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import config from "./config";
import apis from "./../apis";
const { psotBotQywxLoginRequest, getBotQywxAuthUrlRequest } = apis;
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
    ...config,
    routes
});

router.beforeEach((to, from, next) => {
    next();
    if (to.meta) {
        document.title = to.meta.title;
    }
});

export default router;
