
import Vue from 'vue'
import Vuex from 'vuex'
import details from './modules/details.js'
Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        details
    },
  })
export default store
// import Vue from "vue";
// import Vuex from "vuex";

// Vue.use( Vuex );
// export default new Vuex.Store( {
//     state: {
//         hasTabbarPage: [ "/", "/order/factory", "/order/my-order", "/receipt/confirm", "/info" ],
//         areaDataItems: null
//     },
//     mutations: {
//         setAreaData( state, data ) {
//             state.areaDataItems = data;
//         }
//     }
// } );
