import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apis from "./apis";
import storage from "@util/storage.js";
import validate from "@util/validate";
import format from "@util/format.js";
import crypto from "@util/crypto.js";
import getUrlParam from "@util/index.js";
import ElementUI from 'element-ui';
// import Wx from 'weixin-js-sdk';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import "@/styles/index.less";
import "amfe-flexible";
import "vant/lib/index.css";
import 'element-ui/lib/theme-chalk/index.css';
import { Button, Area, Toast, List, PullRefresh, Popup, Lazyload, Dialog, Picker, DatetimePicker, Switch, Field, Icon, Swipe, SwipeItem } from "vant";
Vue.use(Button).use(Toast).use(List).use(PullRefresh).use(Popup).use(Dialog).use(Area).use(Toast).use(Picker).use(DatetimePicker).use(Switch).use(Field).use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
// Vue.use(Wx);
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);

Vue.prototype.$apis = apis;
Vue.prototype.$storage = storage;
Vue.prototype.$validate = validate;
Vue.prototype.$format = format;
Vue.prototype.$crypto = crypto;
Vue.prototype.$getUrlParam = getUrlParam;


Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
(function (doc, win) {
    if (window.location.host == 'case.mrstage.com' || window.location.host == 'case-test.mrstage.com') {
        return
    }
    var docEl = doc.documentElement
    var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
    var recalc = function () {
        var clientWidth = docEl.clientWidth

        if (!clientWidth) return

        docEl.style.fontSize = 100 * (clientWidth / 375) + 'px'
    }

    if (!doc.addEventListener) return

    win.addEventListener(resizeEvt, recalc, false)

    doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
router.beforeEach((to, from, next) => {
    if (to.meta.isShare) {
        const agent = navigator.userAgent
        const isiOS = !!agent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
        // 因为Android是兼容的，所以只需要判断是不是iOS终端
        if (isiOS && to.path !== location.pathname) {
            location.assign(to.fullPath)
        } else {
            next()
        }
    }
    next();
});

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");