import { postRequest, getRequest } from "./../request";
import storage from "./../utils/storage";
import router from "./../router";

//
const noNeedToken = ["/api/v1/wx/get/jsTicket"];

// 检测token
const checkToken = (options, methods) => {
    const exists = noNeedToken.includes(options.url)
    if (exists) {
        return methods === "post" ? postRequest(options) : getRequest(options);
    }
    if (!exists) {
        options.params = options.params || {};
    }
    return methods === "post" ? postRequest(options) : getRequest(options);
};

/**
 * @description 微信jssdk签名
 * @param {object} options
 */
 const getJssdkSignRequest = ( options = {} ) => {
    const url = "/api/v1/wx/get/jsTicket";
    options.url = url;
    return postRequest(options);
};


export default {
    getJssdkSignRequest
};
